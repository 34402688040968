<template>
  <div class="dashboard-filter panel">
    <b-row>
      <b-col cols="12">
        <b-alert
          v-model="alert.show"
          v-height-fade.appear
          :variant="alert.variant"
          dismissible
          class="mb-3"
        >
          <div class="alert-body">
            {{ alert.message }}
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-if="nacional"
        lg="4"
        class="mb-2"
      >
        <SingleSelectFilter
          v-model="selectedGroup"
          :field="'Grupo'"
          :options-dropdown="groupDropdown"
          :deselectable="true"
          field_id="id_grupo"
          field_name="descricao_grupo"
          @input="getBusinessByGroup(); paramsUpdate();"
        />
      </b-col>

      <b-col v-if="nacional" lg="4" class="mb-2">
        <SelectFilterEmpresa ref="selectFilterEmpresa"
          :options="companiesDropdown"
          v-model="selectedCompany"
          :multiple="false"
          @input="paramsUpdate();"
        />
      </b-col>

      <b-col
        v-if="nacional && selectedGroup && hasCompanyUserBusinessGroup"
        lg="4"
        class="mb-2"
      >
        <MultipleSelectFilter
          v-model="selectedBusiness"
          :field="'Negócio'"
          field_id="id_grupo_negocio"
          field_name="descricao"
          :options-dropdown="Array.isArray(businessDropdown) ? businessDropdown : []"
          @input="paramsUpdate"
        />
      </b-col>
      <b-col
        v-if="nacional"
        lg="4"
        class="mb-2"
      >
        <SingleSelectFilter
          v-model="selectedState"
          :field="'UF'"
          :options-dropdown="statesDropdown"
          :deselectable="true"
          field_id="uf"
          field_name="uf"
          tooltip="Filtre para visualizar os dados de acordo com a distribuição estadual das suas empresas"
          @input="paramsUpdate"
        />

      </b-col>

      <b-col
        v-if="nacional"
        lg="4"
        class="mb-2"
      >
        <SingleSelectFilter
          v-model="selectedAdress"
          :field="'Endereço'"
          field_id="id_empresa_endereco"
          field_name="endereco"
          field_label="descricao"
          :disabled="!selectedCompany"
          :options-dropdown="adressesDropdown"
          tooltip="Filtre para visualizar os dados de acordo com o endereço atualizado do cadastro da sua empresa"
        />
      </b-col>

      <b-col
        v-if="nacional"
        lg="4"
        class="mb-2"
      >
        <SingleSelectFilter
          v-model="selectedDepartment"
          :field="'DR SESI'"
          :options-dropdown="departmentDropdown"
          :deselectable="true"
          field_id="id_operador"
          field_name="uf_operador"
          :tooltip="'Filtre para visualizar os dados de acordo com o atendimento realizado pelo SESI'"
          @input="paramsUpdate"
        />
      </b-col>
      <b-col
        v-if="!nacional"
        lg="3"
        class="mb-2"
      >
        <SingleSelectFilter
          v-model="selectedState"
          :field="'Estado'"
          :options-dropdown="filterFields.allStates"
          :deselectable="true"
          field_id="uf"
          field_name="nome_estado"
          @input="paramsUpdate"
        />

      </b-col>
      <b-col lg="4" v-if="!nacional" class="mb-lg-0 mb-1">
          <SelectFilterEmpresa ref="selectFilterEmpresa"
              :options = "companiesDropdown" 
              v-model = "selectedCompanies"
              :multiple = true
          />
      </b-col>
      <b-col :lg="nacional ? 4 : 3">
        <SingleSelectFilter
          v-model="selectedCampaign"
          :field="'Campanha'"
          :options-dropdown="campaignsDropdown"
          @input="resetFilters"
        />
      </b-col>
      <b-col
        cols="12"
      >
        <div
          class="d-flex align-items-center justify-content-center mt-1 pt-1"
        >
          <button
            type="button"
            class="btn custom-blue mr-1"
            @click="filterData"
            :disabled="loadingButton"
          >
            Filtrar
            <span v-if="loadingButton" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
          </button>

          <button
            type="button"
            class="btn custom-outline-blue"
            @click="resetFilters"
          >
            Limpar
          </button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BAlert,
} from 'bootstrap-vue'
import { getArrayAttr } from '@core/utils/utils'
import { heightFade } from '@core/directives/animations'
import dashUtilsMixin from '../../shared-mixins/dashUtilsMixin'
import SingleSelectFilter from '@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue'
import MultipleSelectFilter from '@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue'
import SelectFilterEmpresa from "@/views/custom-pages/dashboard/company/company-components/SelectFilterEmpresa.vue"
import { actions, subjects } from '@/libs/acl/rules'
import {integer} from "@core/utils/validations/validations";

export default {
  components: {
    SingleSelectFilter,
    MultipleSelectFilter,
    BCol,
    BRow,
    BAlert,
    SelectFilterEmpresa
  },

  directives: {
    'height-fade': heightFade,
  },

  mixins: [dashUtilsMixin],

  props: {
    filterFields: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    loadingButton: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      selectedState: null,
      selectedGroup: null,
      selectedBusiness: [],
      selectedCompanies: [],
      selectedAdress: null,
      selectedCompany: null,
      selectedDepartment: null,
      selectedCampaign: this.value,

      businessDropdown: [],

      alert: {
        show: false,
        message: '',
        variant: '',
      },

      all: {
        states: [],
        groups: [],
        departments: [],
        companies: [],
      },
    }
  },

  computed: {
    hasCompanyUserBusinessGroup() {
      return this.$can(actions.VISUALIZAR, subjects.GRUPO_NEGOCIO_ADMIN_EMPRESA)
    },
    nacional() {
      return this.selectedCampaign.nacional
    },
    statesDropdown() {
      const statesSelect = this.filterFields.allStates

      this.orderAlphabetically(statesSelect, 'uf')
      return statesSelect
    },
    groupDropdown() {
      const groupSelect = this.filterFields.allGroups

      this.orderAlphabetically(groupSelect, 'descricao_grupo')
      return groupSelect
    },
    departmentDropdown() {
      const operatorSelect = this.filterFields.allOperators

      this.orderAlphabetically(operatorSelect, 'uf_operador')
      return operatorSelect
    },
    companiesDropdown() {
      const companiesList = this.filterFields.allCompanies
      if (this.selectedCompany) {
        const companyExist = companiesList.find(company => company.id_empresa == this.selectedCompany.id_empresa)
        if (!companyExist) {
          this.selectedCompany = null
        } else {
          this.selectedCompany = companyExist
        }
      }

      this.orderAlphabetically(companiesList, 'nome_empresa')
      return companiesList
    },
    adressesDropdown() {
      let adressesList = []
      if (this.selectedCompany) {
        adressesList = this.selectedCompany.enderecos
      }

      if (this.selectedAdress) {
        const adressExist = adressesList.find(adress => adress.id_empresa_endereco == this.selectedAdress.id_empresa_endereco)
        if (!adressExist) {
          this.selectedAdress = null
        }
      }

      this.orderAlphabetically(adressesList, 'id_empresa_endereco')

      return adressesList
    },
    campaignsDropdown() {
      const campaignsList = this.filterFields.allCampaigns

      this.orderAlphabetically(campaignsList)
      this.orderByRecentDate(campaignsList)
      return campaignsList
    },
    companiesToFilter() {
      let companiesToFilter = []
      if (this.selectedCompany) {
        companiesToFilter = [this.selectedCompany]
      } else if (this.selectedCompanies.length) {
        companiesToFilter = this.selectedCompanies
      } else {
        this.selectedAdress = null
        companiesToFilter = [...this.companiesDropdown]
      }

      companiesToFilter.sort((a, b) => a.id - b.id)
      return companiesToFilter
    },
    adressesToFilter() {
      let adressesToFilter = []

      if (this.selectedAdress) {
        adressesToFilter = [this.selectedAdress.id_empresa_endereco]
      } else if (this.selectedCompany) {
        adressesToFilter = this.selectedCompany.enderecos.map(adress => adress.id_empresa_endereco)
      } else {
        adressesToFilter = this.companiesDropdown.map(company => company.enderecos.map(adress => adress.id_empresa_endereco)).flat()
      }

      return adressesToFilter
    },
  },

  methods: {
    filterData() {
      if (!this.selectedGroup && this.nacional) {
        this.alert = {
          show: true,
          message: 'Você deve selecionar ao menos um grupo de empresas.',
          variant: 'warning',
        }
        return false
      }

      const fieldsToFilter = {
        companies: this.companiesToFilter.map(company => company.id_empresa),
        campaign: [this.selectedCampaign.id],
        adresses: this.nacional ? this.adressesToFilter : [],
        uf: this.selectedState && this.selectedCampaign.nacional ? this.selectedState.uf : null,
        id_operador: this.selectedDepartment ? this.selectedDepartment.id_operador : null,
        nacional: this.nacional,
      }
      return this.$emit('filterUpdade', fieldsToFilter)
    },
    paramsUpdate() {
      let negocio = []

      if (this.selectedBusiness) {
        negocio = this.selectedBusiness.length > 0 ? getArrayAttr(this.selectedBusiness, 'id_grupo_negocio') : []
      }

      this.$emit('paramsUpdate', {
        id_campanha: this.selectedCampaign.id,
        id_grupo: this.selectedGroup ? this.selectedGroup.id_grupo : null,
        negocio,
        id_operador: this.selectedDepartment ? this.selectedDepartment.id_operador : null,
        id_empresa: (this.selectedCompany && typeof this.selectedCompany.id_empresa == 'number')
            ? this.selectedCompany.id_empresa : null,
        uf_empresa: this.selectedState ? this.selectedState.uf : null,
      })

      this.alert = {
        show: false,
        message: '',
        variant: '',
      }
    },
    resetFilters() {
      this.selectedState = null
      this.selectedGroup = null
      this.selectedBusiness = []
      this.selectedDepartment = null
      this.selectedAdress = null
      this.selectedCompany = null
      this.selectedCompanies = []
      this.$refs.selectFilterEmpresa.selectDefaultItem()

      this.$emit('paramsUpdate', {
        id_campanha: this.selectedCampaign.id,
      })
    },

    async getBusinessByGroup() {
      if (this.hasCompanyUserBusinessGroup) {
        const params = {
          idGrupo: this.selectedGroup.id_grupo,
        }

        await this.$http.get(this.$api.grupoNegocio(), { params })
          .then(response => {
            if (response.status === 200) {
              this.businessDropdown = response.data
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
  .filter{
    height: 65px;
  }
</style>
